import Spinner from "./Spinner";

const LoadingSpinner: React.FCC<{ size?: number; isLoading?: boolean }> = ({
  children,
  isLoading = true,
  size,
}) => {
  return isLoading ? (
    <div className={'flex flex-row items-center justify-center space-x-4'}>
      <Spinner size={size} />

      {children && <span className={'text-sm'}>{children}</span>}
    </div>
  ) : null;
};

export default LoadingSpinner;
