import { useMemo } from 'react';
import { Trans } from 'next-i18next';
import type { UserInfo } from 'firebase/auth';
import Link from 'next/link';
import classNames from 'clsx';
import {
  Cog8ToothIcon,
  ArrowLeftOnRectangleIcon,
  EllipsisVerticalIcon,
  NewspaperIcon,
} from '@heroicons/react/24/outline';
import If from '~/core/ui/If';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/core/ui/Dropdown';
import ProfileAvatar from './ProfileAvatar';
import configuration from '~/configuration';
import { useUserProfile } from '~/lib/organizations/hooks/use-user-profile';
import { useCurrentOrganization } from '~/lib/organizations/hooks/use-current-organization';
import ThemeSelectorSubMenu from './ThemeSelectorSubMenu';
import LoadingSpinner from '~/core/ui/LoadingSpinner';

const ProfileDropdown: React.FCC<{
  user: Maybe<UserInfo>;
  signOutRequested: () => void;
  className?: string;
  displayName?: boolean;
  userRole?: number;
  redirectPath?: string;
}> = ({
  user,
  signOutRequested,
  className,
  displayName,
  userRole = 0,
  redirectPath = configuration.paths.feed,
}) => {
  const organization = useCurrentOrganization();

  const organizationId = organization?.id || '';
  const userId = user?.uid || '';

  const { status, data: userProfileSnap } = useUserProfile(organizationId, userId);

  const signedInAsLabel = useMemo(() => {
    return user?.email || user?.phoneNumber || <Trans i18nKey={'common:anonymousUser'} />;
  }, [user]);

  if (!user || !organization || status !== 'success') return <LoadingSpinner />;

  const userProfileData = userProfileSnap?.data();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        data-cy={'profile-dropdown-trigger'}
        className={classNames(
          'flex cursor-pointer focus:outline-none group items-center',
          className,
          {
            ['items-center space-x-2.5 rounded-lg border border-gray-100' +
            ' dark:border-dark-900 p-2 transition-colors' +
            ' hover:bg-gray-50 dark:hover:bg-dark-800/40']: displayName,
          },
        )}
      >
        <ProfileAvatar user={userProfileData} />

        <If condition={displayName}>
          <div className={'flex flex-col text-left w-full truncate'}>
            <span className={'text-sm truncate'}>{userProfileData?.displayName}</span>

            <span className={'text-xs text-gray-500 dark:text-gray-400 truncate'}>
              {signedInAsLabel}
            </span>
          </div>

          <EllipsisVerticalIcon className={'h-8 hidden text-gray-500 group-hover:flex'} />
        </If>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className={'!min-w-[15rem]'}
        collisionPadding={{ left: 25, right: 25 }}
        sideOffset={20}
      >
        <DropdownMenuItem className={'!h-10 rounded-none py-0'}>
          <div className={'flex flex-col justify-start truncate text-left text-xs'}>
            <div className={'text-gray-500 dark:text-gray-400'}>Signed in as</div>

            <div>
              <span className={'block truncate'}>{signedInAsLabel}</span>
            </div>
          </div>
        </DropdownMenuItem>

        <DropdownMenuSeparator />

        <DropdownMenuItem>
          <Link href={redirectPath} className={'flex h-full w-full items-center space-x-2'}>
            {userRole === 0 ? <NewspaperIcon className="h-5" /> : <Cog8ToothIcon className="h-5" />}
            <span>
              <Trans
                i18nKey={userRole === 0 ? 'common:feedGoToOrganization' : 'common:settingsTabLabel'}
              />
            </span>
          </Link>
        </DropdownMenuItem>

        <If condition={configuration.features.enableThemeSwitcher}>
          <ThemeSelectorSubMenu />
        </If>

        <DropdownMenuSeparator />

        <DropdownMenuItem
          role={'button'}
          onClick={signOutRequested}
          className={'flex !cursor-pointer items-center space-x-2'}
        >
          <ArrowLeftOnRectangleIcon className={'h-5'} />

          <span>
            <Trans i18nKey={'auth:signOut'} />
          </span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ProfileDropdown;
