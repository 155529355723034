import React, { useContext, useMemo } from 'react';
import {
  setTheme as setThemeTheming,
  DARK_THEME_CLASSNAME,
  LIGHT_THEME_CLASSNAME,
  SYSTEM_THEME_CLASSNAME,
  getStoredTheme,
} from '~/core/theming';
import { ThemeContext } from '~/core/contexts/theme';
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from '~/core/ui/Dropdown';
import {
  PaintBrushIcon,
  SunIcon,
  ComputerDesktopIcon,
  MoonIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { Trans } from 'next-i18next';
import If from '~/core/ui/If';

const ThemeSelectorSubMenu = () => {
  const currentTheme = useMemo(() => getStoredTheme(), []);
  const { setTheme } = useContext(ThemeContext);

  const Wrapper: React.FCC = ({ children }) => (
    <span className={'flex items-center space-x-2.5'}>{children}</span>
  );

  return (
    <>
      <DropdownMenuSeparator className={'hidden lg:flex'} />

      <DropdownMenuSub>
        <DropdownMenuSubTrigger className={'hidden lg:flex'}>
          <Wrapper>
            <PaintBrushIcon className={'h-5'} />

            <span>
              <Trans i18nKey={'common:theme'} />
            </span>
          </Wrapper>
        </DropdownMenuSubTrigger>

        <DropdownMenuSubContent>
          <DropdownMenuItem
            className={'cursor-pointer flex justify-between items-center'}
            onClick={() => {
              setThemeTheming(LIGHT_THEME_CLASSNAME);
              setTheme(LIGHT_THEME_CLASSNAME);
            }}
          >
            <Wrapper>
              <SunIcon className={'h-4'} />

              <span>
                <Trans i18nKey={'common:lightTheme'} />
              </span>
            </Wrapper>

            <If condition={currentTheme === LIGHT_THEME_CLASSNAME}>
              <CheckCircleIcon className={'h-5'} />
            </If>
          </DropdownMenuItem>

          <DropdownMenuItem
            className={'cursor-pointer flex justify-between items-center'}
            onClick={() => {
              setThemeTheming(DARK_THEME_CLASSNAME);
              setTheme(DARK_THEME_CLASSNAME);
            }}
          >
            <Wrapper>
              <MoonIcon className={'h-4'} />

              <span>
                <Trans i18nKey={'common:darkTheme'} />
              </span>
            </Wrapper>

            <If condition={currentTheme === DARK_THEME_CLASSNAME}>
              <CheckCircleIcon className={'h-5'} />
            </If>
          </DropdownMenuItem>

          <DropdownMenuItem
            className={'cursor-pointer flex justify-between items-center'}
            onClick={() => setThemeTheming(SYSTEM_THEME_CLASSNAME)}
          >
            <Wrapper>
              <ComputerDesktopIcon className={'h-4'} />

              <span>
                <Trans i18nKey={'common:systemTheme'} />
              </span>
            </Wrapper>

            <If condition={currentTheme === SYSTEM_THEME_CLASSNAME}>
              <CheckCircleIcon className={'h-5'} />
            </If>
          </DropdownMenuItem>
        </DropdownMenuSubContent>
      </DropdownMenuSub>
    </>
  );
};

export default ThemeSelectorSubMenu;
