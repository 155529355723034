import { useFirestore, useFirestoreDoc } from 'reactfire';
import { doc, DocumentReference } from 'firebase/firestore';
import { collections } from '~/common/firestore-collections';
import { IUserProfile } from '~/lib/types/organization/userProfile';

/**
 * @name useUserProfile
 * @description Returns the user's profile data
 * @param organizationId
 * @param userId
 */
export function useUserProfile(organizationId: string, userId: string) {
  const firestore = useFirestore();

  const profileRef = doc(
    firestore,
    collections.ORGANIZATIONS,
    organizationId,
    collections.organizations.USER_PROFILES,
    userId,
  ) as DocumentReference<IUserProfile>;

  return useFirestoreDoc(profileRef);
}
